<template>
    <Page>
        <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <ul class="flex-wrap -mb-px hidden md:flex">
                <li
                    v-for="tab in tabs"
                    :key="tab.component"
                    class="mr-2 cursor-pointer"
                    @click="setActiveTab(tab.component)"
                >
                    <span
                        :class="{ 'opacity-100': tab.active, 'opacity-50': !tab.active, 'border-purple-500': tab.active }"
                        class="inline-block uppercase p-4 rounded-t-lg border-b-2 border-transparent text-purple-500 hover:opacity-100 text-xl font-ffdin tracking-normal"
                    >
                        {{ tab.name }}
                    </span>
                </li>
            </ul>
            <SelectInput
                :options="tabs"
                class="md:hidden"
                modelValue="AccountSummary"
                trackBy="component"
                labelKey="name"
                @update:modelValue="setActiveTab"
            />
        </div>
        <BalanceInfo
            class="md:hidden"
            :date="currentDate"
            :balance="balance"
        />
        <keep-alive>
            <component
                :is="activeTab"
                v-if="totals.length"
                :key="activeTab"
                :totals="totals"
            />
        </keep-alive>
    </Page>
</template>

<script>
import Page from '@/components/ui/Page';
import ChargesList from '@/components/payments/ChargesList.vue';
import AccountSummary from '@/components/payments/AccountSummary.vue';
import BalanceInfo from '@/components/payments/BalanceInfo.vue';
import PaymentsList from '@/components/payments/PaymentsList.vue';
import SelectInput from '@/components/ui/SelectInput.vue';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import NotifyMixin from '@/mixins/NotifyMixin';
import { currentDate } from '@/utils/Date.js';
import { mask } from '@/utils/Amount';
import { mapGetters } from 'vuex';

export default {
    components: { Page, ChargesList, AccountSummary, SelectInput, BalanceInfo, PaymentsList },

    mixins: [AccountingQueryManager, NotifyMixin],

    data() {
        return {
            currentDate,

            tabs: [
                {
                    name: this.$t('payments.components.account_summury'),
                    component: 'AccountSummary',
                    active: true,
                },
                {
                    name: this.$t('payments.components.charges_list'),
                    component: 'ChargesList',
                    active: false,
                },
                {
                    name: this.$t('payments.components.payments'),
                    component: 'PaymentsList',
                    active: false,
                },
            ],
        };
    },

    computed: {
        ...mapGetters({
            totals: 'accounting/totals',
            chargeTotals: 'accounting/chargeTotals',
            currentProfile: 'auth/currentProfile',
        }),

        activeTab() {
            return this.tabs.find(tab => tab.active).component;
        },

        balance() {
            return mask(this.chargeTotals ? this.chargeTotals.balance : 0, { prefix: '$' });
        },
    },

    methods: {
        setActiveTab(selectedComponent) {
            this.tabs.forEach(tab => (tab.active = tab.component === selectedComponent));
        },
    },
};
</script>
