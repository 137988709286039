<template>
    <div>
        <div
            v-if="loading"
            class="text-center mt-3"
        >
            {{ $t('app.content_loading') }}
        </div>

        <div
            v-for="(period, periodIndex) in periods"
            :key="periodIndex"
        >
            <TableAccordionItem
                :period="getPeriodDate(period)"
                :balance="currencyFormatter(period.balance)"
                :is-active="period.active"
            >
                <div class="block lg:hidden">
                    <div
                        v-for="(item, index) in period.items"
                        :key="index"
                        class="mt-3"
                    >
                        <TableMobileItem>
                            <template #main>
                                <p>{{ item.chargeName }}</p>
                                <p>{{ currencyFormatter(item.amount) }}</p>
                            </template>
                            <template #additional>
                                <p>{{ item.reference }}</p>
                                <p>{{ dateFormatter(item.dueDate) }}</p>
                            </template>
                        </TableMobileItem>
                    </div>
                </div>

                <TableDesktop class="hidden lg:block">
                    <template #colgroup>
                        <col width="20%" />
                        <col width="20%" />
                        <col width="20%" />
                        <col width="20%" />
                        <col width="0%" />
                    </template>
                    <template #headers>
                        <th>{{ $t('payments.list_columns.name') }}</th>
                        <th>{{ $t('payments.list_columns.reference_no') }}</th>
                        <th>{{ $t('payments.list_columns.date_posted') }}</th>
                        <th>{{ $t('payments.list_columns.date_due') }}</th>
                        <th>{{ $t('payments.list_columns.amount') }}</th>
                    </template>
                    <template #content>
                        <tr
                            v-for="(item, index) in period.items"
                            :key="index"
                        >
                            <td
                                :title="item.chargeName"
                                data-test="chargeName"
                            >
                                {{ item.chargeName }}
                            </td>
                            <td
                                :title="item.reference"
                                data-test="reference"
                            >
                                {{ item.reference }}
                            </td>
                            <td
                                :title="dateFormatter(item.postDate)"
                                data-test="postDate"
                            >
                                {{ dateFormatter(item.postDate) }}
                            </td>
                            <td
                                :title="dateFormatter(item.dueDate)"
                                data-test="dueDate"
                            >
                                {{ dateFormatter(item.dueDate) }}
                            </td>
                            <td
                                :title="currencyFormatter(item.amount)"
                                data-test="amount"
                            >
                                {{ currencyFormatter(item.amount) }}
                            </td>
                        </tr>
                    </template>
                </TableDesktop>
            </TableAccordionItem>
        </div>
    </div>
</template>

<script>
import TableMobileItem from '@/components/payments/components/TableMobileItem';
import TableDesktop from '@/components/payments/components/TableDesktop';
import TableAccordionItem from '@/components/payments/components/TableAccordionItem';
import { dateFormatter, getPeriodDate, getFirstAndLastDatesMonth } from '@/utils/Date';
import { currencyFormatter } from '@/utils/helpers';
import { defaultMeta } from '@/components/dashboard/constants.js';
import AuthMixin from '@/mixins/AuthMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';

export default {
    name: 'ChargesList',

    components: {
        TableAccordionItem,
        TableMobileItem,
        TableDesktop,
    },

    mixins: [AuthMixin, NotifyMixin, AccountingQueryManager],

    props: {
        /**
         * Avaliable totals for certain period
         * with period/balance information
         */
        totals: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            loading: false,
            periods: [],
        };
    },

    created() {
        this.getPeriods();
    },

    methods: {
        dateFormatter,
        getPeriodDate,
        getFirstAndLastDatesMonth,
        currencyFormatter,

        async getChargesList({ startDate, endDate }) {
            const { data } = await this.queryHandler('getListCharges', 'resident', {
                accountId: this.currentProfile.occupancyId,
                sortField: 'createdAt',
                startDate,
                endDate,
                ...defaultMeta,
            });
            return data;
        },

        async getPeriods() {
            this.loading = true;
            try {
                this.periods = await Promise.all(
                    this.totals.map(async (total, i) => {
                        const period = getFirstAndLastDatesMonth({ month: total.period });
                        const charges = await this.getChargesList(period);
                        return {
                            ...total,
                            items: charges,
                            active: i === 0,
                        };
                    })
                );
            } catch (error) {
                this.notifyError(error.message);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
